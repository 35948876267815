.hero h2 {
  text-transform: none !important;
}

.landing-box-container {
  justify-content: space-around !important;
  flex-grow: 1;
  max-width: 740px;
}

.landing-box {
  border-radius: 50%;
  width: 128px;
  height: 128px;
  border: 2px var(--primary) solid;
  position: relative;
  font-family: Maldini;
  font-size: 1.5rem;
  margin-bottom: 20px;
  /* border-radius: 50%; */
  justify-content: space-around;
  padding-top: 19px;
  padding-bottom: 15px;
  letter-spacing: 0.04rem;
}

/*
.landing-box:not(:last-child)::after {
  position: absolute;
  top: 50%;
  left: 100%;
}
*/

.invisible {
  visibility: hidden;
}

.landing-box-carret {
  position: relative;
  transform: translate(3px);
}

.landing-box-carret::after {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  transform: translate(-50%, -50%) rotate(-45deg);
  border: dotted var(--faded);
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 6px;
  content: '';
  position: relative;
}

.info-container {
  position: absolute;
  top: 100%;
  left: 50%;
  font-size: 15pt;
  color: var(--faded);
}
.info {
  transform: translate(-50%, 3px);
}

.landing-box-carret-legend {
  position: absolute;
  top: 70%;
  left: 50%;
  font-size: 1rem;
  font-family: Maldini;
  transform: translate(-50%);
  min-width: 75px;
  text-align: center;
  line-height: 0.95rem;
  color: var(--faded);
}

.landing-box-line {
  content: '';
  width: 75%;
  border-bottom: 4px var(--primary) dotted;
  margin-bottom: 2px;
}

.landing-box-title {
  font-family: Maldini;
  color: var(--primary);
}

:root {
  --d-bright: 0.06;
  --d-dim: 0.02;
}

@keyframes fade-pulse {
  0% {
    opacity: var(--d-bright);
    transform: scale(1.0) translate(-50%) skewX(0);
  }
  22% {
    opacity: var(--d-dim);
    transform: translate(-35%) skewX(5deg);
  }
  28% {
    opacity: var(--d-dim);
    transform: translate(-35%) skewX(5deg);
  }
  50% {
    opacity: var(--d-bright);
  }
  72% {
    opacity: var(--d-dim);
    transform: translate(-65%) skewX(-5deg);
  }
  78% {
    opacity: var(--d-dim);
    transform: translate(-65%) skewX(-5deg);
  }
  100% {
    opacity: var(--d-bright);
    transform: scale(1.0) translate(-50%) skewX(0);
  }
}

.social {
  filter: grayscale(1);
  margin-left: 15px;
  margin-right: 15px;
  opacity: 0.4;
  transform: scale(0.75);
}
.social.flip:hover {
  animation: unsocial2 250ms forwards;
}
.social:hover {
  animation: unsocial 250ms forwards;
}
.social-mini:hover {
  animation: unsocial 250ms forwards;
}
.social.flip {
  transform: scale(-0.75, 0.75);
}

@keyframes unsocial2 {
  100% {
    filter: grayscale(0);
    transform: scale(-1, 1) rotate(360deg);
    opacity: 1;
  }
}

@keyframes unsocial {
  100% {
    filter: grayscale(0);
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}

.social.dark {
  filter: grayscale(1) brightness(3.75);
}

.social.dark:hover {
  filter: grayscale(1) brightness(3.75);
}
